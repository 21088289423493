import Slider from "react-slick";

import Autotivo from './assets/autotivo.png'
import AutotivoWhite from './assets/autotivo-white.png'
import Brands from './assets/brands.png';
import Image1 from './assets/image-1.png';
import Image2 from './assets/image-2.png';
import Image3 from './assets/image-3.png';
import Image4 from './assets/image-4.png';
import Image5 from './assets/image-5.png';
import Image6 from './assets/image-6.png';
import Image7 from './assets/image-7.png';
import Image8 from './assets/image-8.png';
import Image9 from './assets/image-9.png';
import Image10 from './assets/image-10.png';
import Image11 from './assets/image-11.png';
import Image12 from './assets/image-12.png';
import Image13 from './assets/image-13.png';
import Image14 from './assets/image-14.png';
import Image15 from './assets/image-15.png';
import Image16 from './assets/image-16.png';
import Image17 from './assets/image-17.png';
import Image18 from './assets/image-18.png';
import Image19 from './assets/image-19.png';
import Image20 from './assets/image-20.png';
import Image21 from './assets/image-21.png';
import Shopee from './assets/shopee.png';
import Tokopedia from './assets/tokopedia.png';
import Wa from './assets/wa.png';
import Menu from './assets/menu.svg';
import Close from './assets/close.svg';
import { useState } from "react";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: false,
    prevArrow: false,
    appendDots: dots => (
        <div style={{backgroundColor: "#EDEDED", padding: "10px"}}>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
    ),
};

const App = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    return (
        <div className="">
            <nav className="bg-white shadow-xl z-50 sticky top-0">
                <div className="CONTAINER flex items-center justify-between h-16">
                    <img src={Autotivo} alt="autotivo" className="h-8 " />
                    <div className="hidden md:flex items-center gap-5">
                        <p className="capitalize">available at</p>
                        <a href="https://www.tokopedia.com/autotivo" target="_blank" rel="noreferrer"><img src={Tokopedia} alt="tokopedia" /></a>
                        <a href="https://shopee.co.id/autotivo" target="_blank" rel="noreferrer"><img src={Shopee} alt="shopee" /></a>
                        <a href="https://wa.me/+6281119008236" target="_blank" rel="noreferrer">
                            <button className="bg-primary rounded-full flex items-center px-4 py-2 text-white capitalize">
                                <img src={Wa} alt="whatsapp" />
                                <span className="m-0 ml-1">contact us</span>
                            </button>
                        </a>
                    </div>
                    <button onClick={() => setShowDrawer((prev) => !prev)} className="md:hidden w-11 h-11 flex items-center justify-center bg-white border-none p-3 active:bg-gray-100 rounded">
                        <img src={showDrawer ? Close : Menu} className="" alt="" />
                    </button>
                </div>
            </nav>  
            <div className={`${showDrawer ? "top-[60px]" : "-top-full"} shadow flex flex-col gap-3 CONTAINER py-4 bg-white fixed transform duration-500 z-20`}>
                <p className="capitalize">available at</p>
                <a href="https://www.tokopedia.com/autotivo" target="_blank" rel="noreferrer"><img src={Tokopedia} alt="tokopedia" /></a>
                <a href="https://shopee.co.id/autotivo" target="_blank" rel="noreferrer"><img src={Shopee} alt="shopee" /></a>
                <a href="https://wa.me/+6281119008236" target="_blank" rel="noreferrer">
                    <button className="bg-primary rounded-full flex items-center px-4 py-2 text-white capitalize">
                        <img src={Wa} alt="whatsapp" />
                        <span className="m-0 ml-1">contact us</span>
                    </button>
                </a>
            </div>
            <div className="w-full bg-[#EDEDED]">
                <div className="CONTAINER flex-col md:flex-row flex items-center justify-between py-12">
                    <div className="flex-1 flex flex-col items-start">
                        <p className="capitalize text-gray-600 m-0">home of auto accessories</p>
                        <p className="capitalize text-gray-600 text-4xl font-semibold m-0">one stop solution for all your auto accessory needs</p>
                        <p className="capitalize text-gray-600 m-0">We provide all of products type for automotive and many purposes. </p>
                        <button className="capitalize mt-4 bg-primary text-white rounded px-4 py-2">
                            explore
                        </button>
                    </div>
                    <div className="flex-1">
                        <img src={Image1} alt="" className="h-80" />
                    </div>
                </div>
                <div className="w-full CONTAINER">
                    <div className="transform translate-y-1/2 bg-primary rounded-full w-full capitalize text-white text-center py-2 font-medium">
                        the leading automotive accessories retailer
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="CONTAINER flex-col flex md:flex-row item-center justify-between py-14">
                    <div className="flex-1">
                        <img src={Image4} alt="" className="h-56" />
                    </div>
                    <div className="flex-1 mt-4 md:mt-0">
                        <p className="capitalize font-semibold text-gray-600 text-2xl">about autotivo</p>
                        <p className="m-0 text-gray-600 mt-7">AUTOTIVO opened its first store in North Jakarta, Indonesia over 5 years ago in August 2017. AUTOTIVO was created with belief that we can offer every customers properly services, well-organized auto accessory stores and excellent customer service.</p>
                    </div>
                </div>
            </div>
            <div className="w-full relative">
                <img src={Image9} alt="" className="w-full min-h-[300px] object-cover" />
                <div className="CONTAINER flex flex-col items-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <p className="capitalize text-white font-semibold text-2xl">our business core</p>
                    <p className="text-white text-center mt-5">AUTOTIVO has revolutionized the retail business who orient to a customer satisfaction. AUTOTIVO has grown to be the leading retailer of automotive industries. Auto accessories, audio, window film, vehicle lamp are available through AUTOTIVO stores in nation wide.</p>
                </div>
            </div>
            <div className="w-full">
                <div className="CONTAINER grid grid-cols-1 md:grid-cols-2 py-14">
                    <div className="flex-1 h-full flex flex-col justify-center">
                        <p className="capitalize font-semibold text-gray-600 text-2xl">We strive to become leaders in the auto accessories store from Indonesia, </p>
                        <p className="m-0 text-gray-600 mt-7">achieving this result with help of experienced professional team, exceptional service and the most thorough and rigorous selection of suppliers. In addition, we are aware of the entire degree of responsibility, and therefore guarantee the high quality of our products.</p>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <img src={Image2} alt="" className="h-80 object-contain" />
                    </div>
                </div>
            </div>
            <div className="w-full relative">
                <Slider {...settings} arrows={undefined}>
                    <img src={Image11} alt="" className="w-full object-cover min-h-[300px]" />
                    <img src={Image10} alt="" className="w-full object-cover min-h-[300px]" />
                    <img src={Image12} alt="" className="w-full object-cover min-h-[300px]" />
                    <img src={Image8} alt="" className="w-full object-cover min-h-[300px]" />
                </Slider>
            </div>
            <div className="w-full bg-[#EDEDED]">
                <div className="CONTAINER grid grid-cols-1 md:grid-cols-3 py-16 gap-7">
                    <div className="">
                        <p className="text-gray-600 text-2xl">Our <span className="font-semibold">Products</span></p>
                        <p className="text-gray-600 text-xl mt-4">We provide all of products type for automotive and many purposes.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-5 rounded-2xl shadow-2xl mb-3 flex items-center justify-center"><img src={Image5} alt="" className="" /></div>
                        <p className="capitalize font-semibold text-2xl">interior accessories</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-5 rounded-2xl shadow-2xl mb-3 flex items-center justify-center"><img src={Image6} alt="" className="" /></div>
                        <p className="capitalize font-semibold text-2xl">exterior accessories</p>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="CONTAINER bg-white py-10 flex flex-col items-center">
                    <p className="capitalize text-xl">our brands</p>
                    <img src={Brands} alt="" className="object-contain" />
                </div>
            </div>
            <div className="w-full bg-[#045DA8]">
                <div className="CONTAINER  py-10 flex flex-col items-center">
                    <p className="text-white text-2xl capitalize font-semibold">our team value</p>
                    <p className="text-white mt-3 text-center">We have created the AUTOTIVO shopping experience with the customer in mind. Our stores have friendly, knowledgeable Technician who are glad to help you find and install the best things for your car at the right price.</p>
                    <div className="w-full grid grid-cols-3 md:grid-cols-5 gap-4 mt-6">
                        <div className="flex flex-col items-center">
                            <img src={Image19} alt="" className="" />
                            <p className="text-white text-center">Customers First</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={Image21} alt="" className="" />
                            <p className="text-white text-center">Strives for Good Performance</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={Image18} alt="" className="" />
                            <p className="text-white text-center">Solve Every Problems</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={Image20} alt="" className="" />
                            <p className="text-white text-center">Cares About People</p>
                        </div>
                        <div className="flex flex-col items-center">
                            <img src={Image17} alt="" className="" />
                            <p className="text-white text-center">Helps Teams Succeed</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="CONTAINER grid grid-cols-1 md:grid-cols-2 py-14">
                    <div className="flex-1 h-full flex flex-col justify-center">
                        <p className="capitalize font-semibold text-gray-600 text-2xl">Online Distribution Channels</p>
                        <p className="m-0 text-gray-600 mt-7">AUTOTIVO opened its first store in North Jakarta, Indonesia over 5 years ago in August 2017. AUTOTIVO was created with belief that we can offer every customers properly services, well-organized auto accessory stores and excellent customer service.</p>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <img src={Image3} alt="" className="h-80 object-contain" />
                    </div>
                </div>
            </div>
            <div className="bg-black w-full">
                <div className="CONTAINER flex-col md:flex-row flex items-start gap-6 py-10">
                    <img src={AutotivoWhite} className="object-contain mt-6" alt="" />
                    <div className="flex-col xl:flex-row flex w-full items-center mt-8 xl:mt-0">
                        <div className="max-w-[450px]">
                            <p className="text-white font-semibold">PT Autotivo Indonesia International</p>
                            <p className="text-white">Address <br /> Ruko Elang Laut (4), Jl. Pantai Indah Selatan No.43-45, Kamal Muara, Kec. Penjaringan, Kota Jkt Utara, Daerah Khusus Ibukota Jakarta 14470</p>
                        </div>
                        <div className="hidden xl:block bg-white h-[150px] w-[1px] mx-4"></div>
                        <div className="grid grid-cols-2">
                            <a href="https://shopee.co.id/autotivo" target="_blank" rel="noreferrer"><img src={Image13} alt="" className="" /></a>
                            <a href="https://www.tokopedia.com/autotivo" target="_blank" rel="noreferrer"><img src={Image14} alt="" className="" /></a>
                            <a href="https://www.blibli.com/merchant/autotivo/AUO-60022" target="_blank" rel="noreferrer"><img src={Image15} alt="" className="" /></a>
                            <a href="https://www.instagram.com/autotivo/" target="_blank" rel="noreferrer"><img src={Image16} alt="" className="" /></a>
                        </div>
                    </div>
                </div>
                <p className="text-center text-white py-5 font-light text-xs">
                Powered by Crealoka © 2022 Autotivo All rights reserved. 
                </p>
            </div>
        </div>
    )
}

export default App;